import { LanguageCode, LanguageDefinition } from '@shure/shared/models';

/**
 * Simple service for accessing the list of languages supported by the application
 */
export class AppLanguageListService {
	constructor(private readonly languages: LanguageDefinition[]) {
		if (languages.length === 0) {
			throw new Error('Language list is empty!');
		}
	}

	/**
	 * Get the list of languages supported by the application
	 */
	public getLanguageList(): LanguageDefinition[] {
		return this.languages;
	}

	/**
	 * Get the definition for a single language if supported
	 */
	public getLanguage(languageCode: LanguageCode): LanguageDefinition | undefined {
		return this.languages.find((l) => l.id === languageCode);
	}

	/**
	 * Convert a language code string to its LanguageCode enum value.
	 *
	 * This is purely a string-to-enum conversion helper. Even though this maps to an enum value
	 * there's no guarantee that the app language list actually holds that language.
	 *
	 * @returns LanguageCode enum value for input, or undefined if there is no enum value for the input string
	 */
	public stringToLanguageCode(langCodeStr: string): LanguageCode | undefined {
		langCodeStr = langCodeStr.toLocaleLowerCase();
		const langCodes = Object.values(LanguageCode);
		return langCodes.find((l) => l.toString().toLocaleLowerCase() === langCodeStr);
	}
}
