import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';

// import { OrganizationsStoreModule } from '@shure/cloud-apps/organizations/store';

import {
	PrismIconButtonComponent,
	PrismIconComponent,
	PrismMenuComponent,
	PrismMenuItemComponent
} from '@shure/prism-angular-components';

import { FooterComponent } from './footer.component';

@NgModule({
	declarations: [FooterComponent],
	exports: [FooterComponent],
	imports: [
		CommonModule,
		MatMenuModule,
		MatIconModule,
		FlexLayoutModule,
		RouterModule,
		TranslocoModule,
		PrismIconButtonComponent,
		PrismMenuComponent,
		PrismMenuItemComponent,
		PrismIconComponent
		// OrganizationsStoreModule
	]
})
export class FooterModule {}
