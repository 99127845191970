import { footerUrls } from './footer-urls';

type PageKeys = keyof typeof footerUrls;

export const urlLanguageCodes: Map<string, string> = new Map([
	['en', 'en-US'],
	['de', 'de-DE'],
	['fr', 'fr-FR'],
	['es', 'es-LATAM'],
	['pt', 'pt-BR'],
	['ja', 'ja-JP'],
	['ko', 'en-US'],
	['zh-cn', 'zh-CN']
]);
export const urlRegionCodes: Map<string, string> = new Map([
	['en', 'en-US'],
	['de', 'de-DE'],
	['fr', 'fr-CH'],
	['es', 'es'],
	['pt', 'pt-BR'],
	['ja', 'ja'],
	['ko', 'en-US'],
	['zh-cn', 'zh-CN']
]);

/**
 *	formatting footer url's based on the user's locale
 * @param locale - string representation of user's selected locale
 */
export function getFormattedUrl(pageKey: PageKeys): string {
	const storedLang = localStorage.getItem('locale') || 'en';
	const localeCode = urlLanguageCodes.get(storedLang) || 'en-US';
	let url = footerUrls[pageKey] || '';

	if (pageKey === 'CONTACT_US') {
		const regionCode = urlRegionCodes.get(storedLang) || 'en-US';
		url = url.replace('{REGION_CODE}', regionCode);
		if (storedLang === 'zh-cn') {
			return url.replace('{LANGUAGE_CODE}', 'zh_CN');
		}
		const replaceCode =
			storedLang === 'pt' ? 'pt_BR' : storedLang === 'ko' || storedLang === 'en' ? 'en_US' : storedLang;
		return url.replace('{LANGUAGE_CODE}', replaceCode);
	}

	if (storedLang === 'es' && pageKey === 'PRIVACY_POLICY') {
		url = url.replace('privacy', 'privacidad');
	}
	return url.replace('{LANGUAGE_CODE}', localeCode);
}
