export interface CountryDefinition {
	id: string;
	label: string;
	dateFormat?: string;
}

export const SUPPORTED_COUNTRIES: CountryDefinition[] = [
	{ id: 'Afghanistan', label: 'Afghanistan' },
	{ id: 'Aland Islands', label: 'Aland Islands' },
	{ id: 'Albania', label: 'Albania' },
	{ id: 'Algeria', label: 'Algeria' },
	{ id: 'American Virgin Islands', label: 'U.S. Virgin Islands' },
	{ id: 'Andorra', label: 'Andorra' },
	{ id: 'Angola', label: 'Angola' },
	{ id: 'Anguilla', label: 'Anguilla' },
	{ id: 'Antarctica', label: 'Antarctica' },
	{ id: 'Antigua and Barbuda', label: 'Antigua and Barbuda' },
	{ id: 'Argentina', label: 'Argentina' },
	{ id: 'Armenia', label: 'Armenia' },
	{ id: 'Aruba', label: 'Aruba' },
	{ id: 'Australia', label: 'Australia' },
	{ id: 'Austria', label: 'Austria' },
	{ id: 'Azerbaijan', label: 'Azerbaijan' },
	{ id: 'Bahamas', label: 'Bahamas' },
	{ id: 'Bahrain', label: 'Bahrain' },
	{ id: 'Bangladesh', label: 'Bangladesh' },
	{ id: 'Barbados', label: 'Barbados' },
	{ id: 'Belgium', label: 'Belgium' },
	{ id: 'Belize', label: 'Belize' },
	{ id: 'Benin', label: 'Benin' },
	{ id: 'Bermuda', label: 'Bermuda' },
	{ id: 'Bhutan', label: 'Bhutan' },
	{ id: 'Bolivia, Plurinational State of', label: 'Bolivia' },
	{ id: 'Bonaire, Sint Eustatius and Saba', label: 'Bonaire, Sint Eustatius and Saba' },
	{ id: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
	{ id: 'Botswana', label: 'Botswana' },
	{ id: 'Bouvet Island', label: 'Bouvet Island' },
	{ id: 'Br West Indies', label: 'Br West Indies' },
	{ id: 'Brazil', label: 'Brazil' },
	{ id: 'British Indian Ocean Territory', label: 'British Indian Ocean Territory' },
	{ id: 'Brunei Darussalam', label: 'Brunei Darussalam' },
	{ id: 'Bulgaria', label: 'Bulgaria' },
	{ id: 'Burkina Faso', label: 'Burkina Faso' },
	{ id: 'Burundi', label: 'Burundi' },
	{ id: 'Cambodia', label: 'Cambodia' },
	{ id: 'Cameroon', label: 'Cameroon' },
	{ id: 'Canada', label: 'Canada' },
	{ id: 'Cape Verde', label: 'Cape Verde' },
	{ id: 'Cayman Islands', label: 'Cayman Islands' },
	{ id: 'Central African Republic', label: 'Central African Republic' },
	{ id: 'Chad', label: 'Chad' },
	{ id: 'Chile', label: 'Chile' },
	{ id: 'China', label: 'China' },
	{ id: 'Chinese Taipei', label: 'Taiwan' },
	{ id: 'Christmas Island', label: 'Christmas Island' },
	{ id: 'Cocos (Keeling) Islands', label: 'Cocos (Keeling) Islands' },
	{ id: 'Colombia', label: 'Colombia' },
	{ id: 'Comoros', label: 'Comoros' },
	{ id: 'Congo', label: 'Congo' },
	{ id: 'Congo, the Democratic Republic of the', label: 'Democratic Republic of the Congo' },
	{ id: 'Cook Islands', label: 'Cook Islands' },
	{ id: 'Costa Rica', label: 'Costa Rica' },
	{ id: 'Cote d’Ivoire', label: 'Cote d’Ivoire' },
	{ id: 'Croatia', label: 'Croatia' },
	{ id: 'Curaçao', label: 'Curaçao' },
	{ id: 'Cyprus', label: 'Cyprus' },
	{ id: 'Czech Republic', label: 'Czech Republic' },
	{ id: 'Denmark', label: 'Denmark' },
	{ id: 'Djibouti', label: 'Djibouti' },
	{ id: 'Dominica', label: 'Dominica' },
	{ id: 'Dominican Republic', label: 'Dominican Republic' },
	{ id: 'Dutch Antilles', label: 'Dutch Antilles' },
	{ id: 'Ecuador', label: 'Ecuador' },
	{ id: 'Egypt', label: 'Egypt' },
	{ id: 'El Salvador', label: 'El Salvador' },
	{ id: 'England', label: 'England' },
	{ id: 'Equatorial Guinea', label: 'Equatorial Guinea' },
	{ id: 'Eritrea', label: 'Eritrea' },
	{ id: 'Estonia', label: 'Estonia' },
	{ id: 'Ethiopia', label: 'Ethiopia' },
	{ id: 'Falkland Islands (Malvinas)', label: 'Falkland Islands' },
	{ id: 'Faroe Islands', label: 'Faroe Islands' },
	{ id: 'Fiji', label: 'Fiji' },
	{ id: 'Finland', label: 'Finland' },
	{ id: 'France', label: 'France' },
	{ id: 'French Guiana', label: 'French Guiana' },
	{ id: 'French Polynesia', label: 'French Polynesia' },
	{ id: 'French Southern Territories', label: 'French Southern Territories' },
	{ id: 'Gabon', label: 'Gabon' },
	{ id: 'Gambia', label: 'Gambia' },
	{ id: 'Georgia', label: 'Georgia' },
	{ id: 'Germany', label: 'Germany' },
	{ id: 'Ghana', label: 'Ghana' },
	{ id: 'Gibraltar', label: 'Gibraltar' },
	{ id: 'Greece', label: 'Greece' },
	{ id: 'Greenland', label: 'Greenland' },
	{ id: 'Grenada', label: 'Grenada' },
	{ id: 'Guadeloupe', label: 'Guadeloupe' },
	{ id: 'Guam', label: 'Guam' },
	{ id: 'Guatemala', label: 'Guatemala' },
	{ id: 'Guernsey', label: 'Guernsey' },
	{ id: 'Guinea', label: 'Guinea' },
	{ id: 'Guinea-Bissau', label: 'Guinea-Bissau' },
	{ id: 'Guyana', label: 'Guyana' },
	{ id: 'Haiti', label: 'Haiti' },
	{ id: 'Heard Island and McDonald Islands', label: 'Heard Island and McDonald Islands' },
	{ id: 'Holy See (Vatican City State)', label: 'Holy See (Vatican City State)' },
	{ id: 'Honduras', label: 'Honduras' },
	{ id: 'Hong Kong S.A.R.', label: 'Hong Kong S.A.R.' },
	{ id: 'Hungary', label: 'Hungary' },
	{ id: 'Iceland', label: 'Iceland' },
	{ id: 'India', label: 'India' },
	{ id: 'Indonesia', label: 'Indonesia' },
	{ id: 'Iraq', label: 'Iraq' },
	{ id: 'Ireland', label: 'Ireland' },
	{ id: 'Isle of Man', label: 'Isle of Man' },
	{ id: 'Israel', label: 'Israel' },
	{ id: 'Italy', label: 'Italy' },
	{ id: 'Jamaica', label: 'Jamaica' },
	{ id: 'Japan', label: 'Japan' },
	{ id: 'Jersey', label: 'Jersey' },
	{ id: 'Jordan', label: 'Jordan' },
	{ id: 'Kazakhstan', label: 'Kazakhstan' },
	{ id: 'Kenya', label: 'Kenya' },
	{ id: 'Kiribati', label: 'Kiribati' },
	{ id: 'Korea, Republic of', label: 'South Korea' },
	{ id: 'Kuwait', label: 'Kuwait' },
	{ id: 'Kyrgyzstan', label: 'Kyrgyzstan' },
	{ id: 'Lao Peoples Democratic Republic', label: 'Laos' },
	{ id: 'Latvia', label: 'Latvia' },
	{ id: 'Lebanon', label: 'Lebanon' },
	{ id: 'Lesotho', label: 'Lesotho' },
	{ id: 'Liberia', label: 'Liberia' },
	{ id: 'Libyan Arab Jamahiriya', label: 'Libyan Arab Jamahiriya' },
	{ id: 'Liechtenstein', label: 'Liechtenstein' },
	{ id: 'Lithuania', label: 'Lithuania' },
	{ id: 'Luxembourg', label: 'Luxembourg' },
	{ id: 'Macao', label: 'Macao S.A.R., China' },
	{ id: 'Macedonia, the former Yugoslav Republic of', label: 'Macedonia' },
	{ id: 'Madagascar', label: 'Madagascar' },
	{ id: 'Malawi', label: 'Malawi' },
	{ id: 'Malaysia', label: 'Malaysia' },
	{ id: 'Maldives', label: 'Maldives' },
	{ id: 'Mali', label: 'Mali' },
	{ id: 'Malta', label: 'Malta' },
	{ id: 'Martinique', label: 'Martinique' },
	{ id: 'Mauritania', label: 'Mauritania' },
	{ id: 'Mauritius', label: 'Mauritius' },
	{ id: 'Mayotte', label: 'Mayotte' },
	{ id: 'Mexico', label: 'Mexico' },
	{ id: 'Moldova, Republic of', label: 'Moldova' },
	{ id: 'Monaco', label: 'Monaco' },
	{ id: 'Mongolia', label: 'Mongolia' },
	{ id: 'Montenegro', label: 'Montenegro' },
	{ id: 'Montserrat', label: 'Montserrat' },
	{ id: 'Morocco', label: 'Morocco' },
	{ id: 'Mozambique', label: 'Mozambique' },
	{ id: 'Myanmar', label: 'Myanmar' },
	{ id: 'N. Mariana Island', label: 'N. Mariana Island' },
	{ id: 'Namibia', label: 'Namibia' },
	{ id: 'Nauru', label: 'Nauru' },
	{ id: 'Nepal', label: 'Nepal' },
	{ id: 'Netherlands', label: 'Netherlands' },
	{ id: 'New Caledonia', label: 'New Caledonia' },
	{ id: 'New Zealand', label: 'New Zealand' },
	{ id: 'Nicaragua', label: 'Nicaragua' },
	{ id: 'Niger', label: 'Niger' },
	{ id: 'Nigeria', label: 'Nigeria' },
	{ id: 'Niue', label: 'Niue' },
	{ id: 'Norfolk Island', label: 'Norfolk Island' },
	{ id: 'Norway', label: 'Norway' },
	{ id: 'Oman', label: 'Oman' },
	{ id: 'Pakistan', label: 'Pakistan' },
	{ id: 'Palestinian Territory, Occupied', label: 'Palestinian Territory' },
	{ id: 'Panama', label: 'Panama' },
	{ id: 'Papua New Guinea', label: 'Papua New Guinea' },
	{ id: 'Paraguay', label: 'Paraguay' },
	{ id: 'Peru', label: 'Peru' },
	{ id: 'Philippines', label: 'Philippines' },
	{ id: 'Pitcairn', label: 'Pitcairn' },
	{ id: 'Poland', label: 'Poland' },
	{ id: 'Portugal', label: 'Portugal' },
	{ id: 'Puerto Rico', label: 'Puerto Rico' },
	{ id: 'Qatar', label: 'Qatar' },
	{ id: 'Reunion', label: 'Reunion' },
	{ id: 'Romania', label: 'Romania' },
	{ id: 'Rwanda', label: 'Rwanda' },
	{ id: 'Saint Barthélemy', label: 'Saint Barthélemy' },
	{ id: 'Saint Helena, Ascension and Tristan da Cunha', label: 'Saint Helena, Ascension and Tristan da Cunha' },
	{ id: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
	{ id: 'Saint Lucia', label: 'Saint Lucia' },
	{ id: 'Saint Martin (French part)', label: 'Saint Martin (French part)' },
	{ id: 'Saint Pierre and Miquelon', label: 'Saint Pierre and Miquelon' },
	{ id: 'Saint Vincent and the Grenadines', label: 'Saint Vincent and the Grenadines' },
	{ id: 'Samoa', label: 'Samoa' },
	{ id: 'San Marino', label: 'San Marino' },
	{ id: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },
	{ id: 'Saudi Arabia', label: 'Saudi Arabia' },
	{ id: 'Senegal', label: 'Senegal' },
	{ id: 'Serbia', label: 'Serbia' },
	{ id: 'Seychelles', label: 'Seychelles' },
	{ id: 'Sierra Leone', label: 'Sierra Leone' },
	{ id: 'Singapore', label: 'Singapore' },
	{ id: 'Sint Maarten (Dutch part)', label: 'Sint Maarten (Dutch part)' },
	{ id: 'Slovakia', label: 'Slovakia' },
	{ id: 'Slovenia', label: 'Slovenia' },
	{ id: 'Solomon Islands', label: 'Solomon Islands' },
	{ id: 'Somalia', label: 'Somalia' },
	{ id: 'South Africa', label: 'South Africa' },
	{ id: 'South Georgia and the South Sandwich Islands', label: 'South Georgia and the South Sandwich Islands' },
	{ id: 'South Sudan', label: 'South Sudan' },
	{ id: 'Spain', label: 'Spain' },
	{ id: 'Sri Lanka', label: 'Sri Lanka' },
	{ id: 'Suriname', label: 'Suriname' },
	{ id: 'Svalbard and Jan Mayen', label: 'Svalbard and Jan Mayen' },
	{ id: 'Swaziland', label: 'Swaziland' },
	{ id: 'Sweden', label: 'Sweden' },
	{ id: 'Switzerland', label: 'Switzerland' },
	{ id: 'Tajikistan', label: 'Tajikistan' },
	{ id: 'Tanzania, United Republic of', label: 'Tanzania' },
	{ id: 'Thailand', label: 'Thailand' },
	{ id: 'Timor-Leste', label: 'Timor-Leste' },
	{ id: 'Togo', label: 'Togo' },
	{ id: 'Tokelau', label: 'Tokelau' },
	{ id: 'Tonga', label: 'Tonga' },
	{ id: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
	{ id: 'Tunisia', label: 'Tunisia' },
	{ id: 'Turkey', label: 'Turkey' },
	{ id: 'Turkmenistan', label: 'Turkmenistan' },
	{ id: 'Turks and Caicos Islands', label: 'Turks and Caicos Islands' },
	{ id: 'Tuvalu', label: 'Tuvalu' },
	{ id: 'Uganda', label: 'Uganda' },
	{ id: 'United Arab Emirates', label: 'United Arab Emirates' },
	{ id: 'United Kingdom', label: 'United Kingdom' },
	{ id: 'United States', label: 'United States' },
	{ id: 'Uruguay', label: 'Uruguay' },
	{ id: 'Uzbekistan', label: 'Uzbekistan' },
	{ id: 'Vanuatu', label: 'Vanuatu' },
	{ id: 'Venezuela, Bolivarian Republic of', label: 'Venezuela, Bolivarian Republic of' },
	{ id: 'Viet Nam', label: 'Vietnam' },
	{ id: 'Virgin Islands, British', label: 'British Virgin Islands' },
	{ id: 'Wallis and Futuna', label: 'Wallis and Futuna' },
	{ id: 'Western Sahara', label: 'Western Sahara' },
	{ id: 'Yemen', label: 'Yemen' },
	{ id: 'Zambia', label: 'Zambia' },
	{ id: 'Zimbabwe', label: 'Zimbabwe' }
];

//TODO: MM - Confirm why supported countries is different for My Account and Create Org
export const SUPPORTED_COUNTRIES_MY_ACCOUNT: CountryDefinition[] = [
	{
		id: 'Afghanistan',
		label: 'afghanistan'
	},
	{
		id: 'Aland Islands',
		label: 'aland-islands'
	},
	{
		id: 'Albania',
		label: 'albania',
		dateFormat: 'yyyy-mm-dd'
	},
	{
		id: 'Algeria',
		label: 'algeria'
	},
	{
		id: 'American Virgin Islands',
		label: 'u.s.-virgin-islands'
	},
	{
		id: 'Andorra',
		label: 'andorra'
	},
	{
		id: 'Angola',
		label: 'angola'
	},
	{
		id: 'Anguilla',
		label: 'anguilla'
	},
	{
		id: 'Antarctica',
		label: 'antarctica'
	},
	{
		id: 'Antigua and Barbuda',
		label: 'antigua-and-barbuda'
	},
	{
		id: 'Argentina',
		label: 'argentina'
	},
	{
		id: 'Armenia',
		label: 'armenia',
		dateFormat: 'yyyy-mm-dd'
	},
	{
		id: 'Aruba',
		label: 'aruba'
	},
	{
		id: 'Australia',
		label: 'australia',
		dateFormat: 'dd-mm-yyyy'
	},
	{
		id: 'Austria',
		label: 'austria',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Azerbaijan',
		label: 'azerbaijan',
		dateFormat: 'yyyy-mm-dd'
	},
	{
		id: 'Bahamas',
		label: 'bahamas'
	},
	{
		id: 'Bahrain',
		label: 'bahrain'
	},
	{
		id: 'Bangladesh',
		label: 'bangladesh',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Barbados',
		label: 'barbados'
	},
	{
		id: 'Belgium',
		label: 'belgium',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Belize',
		label: 'belize'
	},
	{
		id: 'Benin',
		label: 'benin'
	},
	{
		id: 'Bermuda',
		label: 'bermuda'
	},
	{
		id: 'Bhutan',
		label: 'bhutan'
	},
	{
		id: 'Bolivia, Plurinational State of',
		label: 'bolivia'
	},
	{
		id: 'Bonaire, Sint Eustatius and Saba',
		label: 'bonaire,-sint-eustatius-and-saba'
	},
	{
		id: 'Bosnia and Herzegovina',
		label: 'bosnia-and-herzegovina'
	},
	{
		id: 'Botswana',
		label: 'botswana'
	},
	{
		id: 'Bouvet Island',
		label: 'bouvet-island'
	},
	{
		id: 'Br West Indies',
		label: 'br-west-indies'
	},
	{
		id: 'Brazil',
		label: 'brazil',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'British Indian Ocean Territory',
		label: 'british-indian-ocean-territory'
	},
	{
		id: 'Brunei Darussalam',
		label: 'brunei-darussalam'
	},
	{
		id: 'Bulgaria',
		label: 'bulgaria',
		dateFormat: 'dd.mm.yyyy'
	},
	{
		id: 'Burkina Faso',
		label: 'burkina-faso'
	},
	{
		id: 'Burundi',
		label: 'burundi'
	},
	{
		id: 'Cambodia',
		label: 'cambodia',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Cameroon',
		label: 'cameroon'
	},
	{
		id: 'Canada',
		label: 'canada',
		dateFormat: 'mm-dd-yyyy'
	},
	{
		id: 'Cape Verde',
		label: 'cape-verde'
	},
	{
		id: 'Cayman Islands',
		label: 'cayman-islands'
	},
	{
		id: 'Central African Republic',
		label: 'central-african-republic'
	},
	{
		id: 'Chad',
		label: 'chad'
	},
	{
		id: 'Chile',
		label: 'chile'
	},
	{
		id: 'China',
		label: 'china',
		dateFormat: 'mm/dd/yyyy'
	},
	{
		id: 'Chinese Taipei',
		label: 'taiwan',
		dateFormat: 'mm-dd-yyyy'
	},
	{
		id: 'Christmas Island',
		label: 'christmas-island'
	},
	{
		id: 'Cocos (Keeling) Islands',
		label: 'cocos-(keeling)-islands'
	},
	{
		id: 'Colombia',
		label: 'colombia'
	},
	{
		id: 'Comoros',
		label: 'comoros'
	},
	{
		id: 'Congo',
		label: 'congo'
	},
	{
		id: 'Congo, the Democratic Republic of the',
		label: 'democratic-republic-of-the-congo'
	},
	{
		id: 'Cook Islands',
		label: 'cook-islands'
	},
	{
		id: 'Costa Rica',
		label: 'costa-rica'
	},
	{
		id: 'Cote d’Ivoire',
		label: 'cote-divoire'
	},
	{
		id: 'Croatia',
		label: 'croatia',
		dateFormat: 'yyyy-mm-dd'
	},
	{
		id: 'Curaçao',
		label: 'curaçao'
	},
	{
		id: 'Cyprus',
		label: 'cyprus'
	},
	{
		id: 'Czech Republic',
		label: 'czech-republic',
		dateFormat: 'yyyy-mm-dd'
	},
	{
		id: 'Denmark',
		label: 'denmark',
		dateFormat: 'dd-mm-yyyy'
	},
	{
		id: 'Djibouti',
		label: 'djibouti'
	},
	{
		id: 'Dominica',
		label: 'dominica'
	},
	{
		id: 'Dominican Republic',
		label: 'dominican-republic'
	},
	{
		id: 'Dutch Antilles',
		label: 'dutch-antilles'
	},
	{
		id: 'Ecuador',
		label: 'ecuador'
	},
	{
		id: 'Egypt',
		label: 'egypt'
	},
	{
		id: 'El Salvador',
		label: 'el-salvador'
	},
	{
		id: 'England',
		label: 'england'
	},
	{
		id: 'Equatorial Guinea',
		label: 'equatorial-guinea'
	},
	{
		id: 'Eritrea',
		label: 'eritrea'
	},
	{
		id: 'Estonia',
		label: 'estonia',
		dateFormat: 'dd-mm-yyyy'
	},
	{
		id: 'Ethiopia',
		label: 'ethiopia',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Falkland Islands (Malvinas)',
		label: 'falkland-islands'
	},
	{
		id: 'Faroe Islands',
		label: 'faroe-islands'
	},
	{
		id: 'Fiji',
		label: 'fiji'
	},
	{
		id: 'Finland',
		label: 'finland',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'France',
		label: 'france',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'French Guiana',
		label: 'french-guiana'
	},
	{
		id: 'French Polynesia',
		label: 'french-polynesia'
	},
	{
		id: 'French Southern Territories',
		label: 'french-southern-territories'
	},
	{
		id: 'Gabon',
		label: 'gabon'
	},
	{
		id: 'Gambia',
		label: 'gambia'
	},
	{
		id: 'Georgia',
		label: 'georgia',
		dateFormat: 'yyyy-mm-dd'
	},
	{
		id: 'Germany',
		label: 'germany',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Ghana',
		label: 'ghana'
	},
	{
		id: 'Gibraltar',
		label: 'gibraltar'
	},
	{
		id: 'Greece',
		label: 'greece',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Greenland',
		label: 'greenland'
	},
	{
		id: 'Grenada',
		label: 'grenada'
	},
	{
		id: 'Guadeloupe',
		label: 'guadeloupe'
	},
	{
		id: 'Guam',
		label: 'guam'
	},
	{
		id: 'Guatemala',
		label: 'guatemala'
	},
	{
		id: 'Guernsey',
		label: 'guernsey'
	},
	{
		id: 'Guinea',
		label: 'guinea'
	},
	{
		id: 'Guinea-Bissau',
		label: 'guinea-bissau'
	},
	{
		id: 'Guyana',
		label: 'guyana'
	},
	{
		id: 'Haiti',
		label: 'haiti'
	},
	{
		id: 'Heard Island and McDonald Islands',
		label: 'heard-island-and-mcdonald-islands'
	},
	{
		id: 'Holy See (Vatican City State)',
		label: 'holy-see-(vatican-city-state)'
	},
	{
		id: 'Honduras',
		label: 'honduras'
	},
	{
		id: 'Hong Kong S.A.R.',
		label: 'hong-kong-s.a.r.',
		dateFormat: 'yyyy-mm-dd'
	},
	{
		id: 'Hungary',
		label: 'hungary',
		dateFormat: 'yyyy-mm-dd'
	},
	{
		id: 'Iceland',
		label: 'iceland',
		dateFormat: 'dd-mm-yyyy'
	},
	{
		id: 'India',
		label: 'india',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Indonesia',
		label: 'indonesia',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Iraq',
		label: 'iraq'
	},
	{
		id: 'Ireland',
		label: 'ireland',
		dateFormat: 'mm-dd-yyyy'
	},
	{
		id: 'Isle of Man',
		label: 'isle-of-man'
	},
	{
		id: 'Israel',
		label: 'israel',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Italy',
		label: 'italy',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Jamaica',
		label: 'jamaica'
	},
	{
		id: 'Japan',
		label: 'japan',
		dateFormat: 'mm/dd/yyyy'
	},
	{
		id: 'Jersey',
		label: 'jersey'
	},
	{
		id: 'Jordan',
		label: 'jordan'
	},
	{
		id: 'Kazakhstan',
		label: 'kazakhstan',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Kenya',
		label: 'kenya',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Kiribati',
		label: 'kiribati'
	},
	{
		id: 'Korea, Republic of',
		label: 'south-korea',
		dateFormat: 'mm/dd/yyyy'
	},
	{
		id: 'Kuwait',
		label: 'kuwait'
	},
	{
		id: 'Kyrgyzstan',
		label: 'kyrgyzstan'
	},
	{
		id: 'Lao Peoples Democratic Republic',
		label: 'laos'
	},
	{
		id: 'Latvia',
		label: 'latvia',
		dateFormat: 'yyyy.dd.mm'
	},
	{
		id: 'Lebanon',
		label: 'lebanon'
	},
	{
		id: 'Lesotho',
		label: 'lesotho'
	},
	{
		id: 'Liberia',
		label: 'liberia'
	},
	{
		id: 'Libyan Arab Jamahiriya',
		label: 'libyan-arab-jamahiriya'
	},
	{
		id: 'Liechtenstein',
		label: 'liechtenstein'
	},
	{
		id: 'Lithuania',
		label: 'lithuania',
		dateFormat: 'yyyy.mm.dd'
	},
	{
		id: 'Luxembourg',
		label: 'luxembourg'
	},
	{
		id: 'Macao',
		label: 'macao-s.a.r.,-china'
	},
	{
		id: 'Macedonia, the former Yugoslav Republic of',
		label: 'macedonia'
	},
	{
		id: 'Madagascar',
		label: 'madagascar'
	},
	{
		id: 'Malawi',
		label: 'malawi'
	},
	{
		id: 'Malaysia',
		label: 'malaysia',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Maldives',
		label: 'maldives'
	},
	{
		id: 'Mali',
		label: 'mali'
	},
	{
		id: 'Malta',
		label: 'malta',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Martinique',
		label: 'martinique'
	},
	{
		id: 'Mauritania',
		label: 'mauritania'
	},
	{
		id: 'Mauritius',
		label: 'mauritius'
	},
	{
		id: 'Mayotte',
		label: 'mayotte'
	},
	{
		id: 'Mexico',
		label: 'mexico',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Moldova, Republic of',
		label: 'moldova'
	},
	{
		id: 'Monaco',
		label: 'monaco'
	},
	{
		id: 'Mongolia',
		label: 'mongolia'
	},
	{
		id: 'Montenegro',
		label: 'montenegro',
		dateFormat: 'yyyy-mm-dd'
	},
	{
		id: 'Montserrat',
		label: 'montserrat'
	},
	{
		id: 'Morocco',
		label: 'morocco'
	},
	{
		id: 'Mozambique',
		label: 'mozambique'
	},
	{
		id: 'Myanmar',
		label: 'myanmar'
	},
	{
		id: 'N. Mariana Island',
		label: 'n.-mariana-island'
	},
	{
		id: 'Namibia',
		label: 'namibia'
	},
	{
		id: 'Nauru',
		label: 'nauru'
	},
	{
		id: 'Nepal',
		label: 'nepal',
		dateFormat: 'yyyy-mm-dd'
	},
	{
		id: 'Netherlands',
		label: 'netherlands',
		dateFormat: 'dd-mm-yyyy'
	},
	{
		id: 'New Caledonia',
		label: 'new-caledonia'
	},
	{
		id: 'New Zealand',
		label: 'new-zealand',
		dateFormat: 'dd-mm-yyyy'
	},
	{
		id: 'Nicaragua',
		label: 'nicaragua'
	},
	{
		id: 'Niger',
		label: 'niger'
	},
	{
		id: 'Nigeria',
		label: 'nigeria',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Niue',
		label: 'niue'
	},
	{
		id: 'Norfolk Island',
		label: 'norfolk-island'
	},
	{
		id: 'Norway',
		label: 'norway',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Oman',
		label: 'oman'
	},
	{
		id: 'Pakistan',
		label: 'pakistan',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Palestinian Territory, Occupied',
		label: 'palestinian-territory'
	},
	{
		id: 'Panama',
		label: 'panama'
	},
	{
		id: 'Papua New Guinea',
		label: 'papua-new-guinea'
	},
	{
		id: 'Paraguay',
		label: 'paraguay'
	},
	{
		id: 'Peru',
		label: 'peru'
	},
	{
		id: 'Philippines',
		label: 'philippines',
		dateFormat: 'yyyy-mm-dd'
	},
	{
		id: 'Pitcairn',
		label: 'pitcairn'
	},
	{
		id: 'Poland',
		label: 'poland',
		dateFormat: 'yyyy-mm-dd'
	},
	{
		id: 'Portugal',
		label: 'portugal',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Puerto Rico',
		label: 'puerto-rico'
	},
	{
		id: 'Qatar',
		label: 'qatar'
	},
	{
		id: 'Reunion',
		label: 'reunion'
	},
	{
		id: 'Romania',
		label: 'romania',
		dateFormat: 'yyyy-mm-dd'
	},
	{
		id: 'Rwanda',
		label: 'rwanda',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Saint Barthélemy',
		label: 'saint-barthélemy'
	},
	{
		id: 'Saint Helena, Ascension and Tristan da Cunha',
		label: 'saint-helena,-ascension-and-tristan-da-cunha'
	},
	{
		id: 'Saint Kitts and Nevis',
		label: 'saint-kitts-and-nevis'
	},
	{
		id: 'Saint Lucia',
		label: 'saint-lucia'
	},
	{
		id: 'Saint Martin (French part)',
		label: 'saint-martin-(french-part)'
	},
	{
		id: 'Saint Pierre and Miquelon',
		label: 'saint-pierre-and-miquelon'
	},
	{
		id: 'Saint Vincent and the Grenadines',
		label: 'saint-vincent-and-the-grenadines'
	},
	{
		id: 'Samoa',
		label: 'samoa'
	},
	{
		id: 'San Marino',
		label: 'san-marino'
	},
	{
		id: 'Sao Tome and Principe',
		label: 'sao-tome-and-principe'
	},
	{
		id: 'Saudi Arabia',
		label: 'saudi-arabia'
	},
	{
		id: 'Senegal',
		label: 'senegal',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Serbia',
		label: 'serbia',
		dateFormat: 'yyyy-mm-dd'
	},
	{
		id: 'Seychelles',
		label: 'seychelles'
	},
	{
		id: 'Sierra Leone',
		label: 'sierra-leone'
	},
	{
		id: 'Singapore',
		label: 'singapore'
	},
	{
		id: 'Sint Maarten (Dutch part)',
		label: 'sint-maarten-(dutch-part)'
	},
	{
		id: 'Slovakia',
		label: 'slovakia',
		dateFormat: 'yyyy-mm-dd'
	},
	{
		id: 'Slovenia',
		label: 'slovenia',
		dateFormat: 'dd-mm-yyyy'
	},
	{
		id: 'Solomon Islands',
		label: 'solomon-islands'
	},
	{
		id: 'Somalia',
		label: 'somalia'
	},
	{
		id: 'South Africa',
		label: 'south-africa',
		dateFormat: 'mm-dd-yyyy'
	},
	{
		id: 'South Georgia and the South Sandwich Islands',
		label: 'south-georgia-and-the-south-sandwich-islands'
	},
	{
		id: 'South Sudan',
		label: 'south-sudan'
	},
	{
		id: 'Spain',
		label: 'spain',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Sri Lanka',
		label: 'sri-lanka',
		dateFormat: 'dd-mm-yyyy'
	},
	{
		id: 'Suriname',
		label: 'suriname'
	},
	{
		id: 'Svalbard and Jan Mayen',
		label: 'svalbard-and-jan-mayen'
	},
	{
		id: 'Swaziland',
		label: 'swaziland'
	},
	{
		id: 'Sweden',
		label: 'sweden',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Switzerland',
		label: 'switzerland',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Tajikistan',
		label: 'tajikistan'
	},
	{
		id: 'Tanzania, United Republic of',
		label: 'tanzania',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Thailand',
		label: 'thailand',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Timor-Leste',
		label: 'timor-leste'
	},
	{
		id: 'Togo',
		label: 'togo'
	},
	{
		id: 'Tokelau',
		label: 'tokelau'
	},
	{
		id: 'Tonga',
		label: 'tonga'
	},
	{
		id: 'Trinidad and Tobago',
		label: 'trinidad-and-tobago'
	},
	{
		id: 'Tunisia',
		label: 'tunisia'
	},
	{
		id: 'Turkey',
		label: 'turkey',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Turkmenistan',
		label: 'turkmenistan'
	},
	{
		id: 'Turks and Caicos Islands',
		label: 'turks-and-caicos-islands'
	},
	{
		id: 'Tuvalu',
		label: 'tuvalu'
	},
	{
		id: 'Uganda',
		label: 'uganda',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'United Arab Emirates',
		label: 'united-arab-emirates'
	},
	{
		id: 'United Kingdom',
		label: 'united-kingdom',
		dateFormat: 'mm-dd-yyyy'
	},
	{
		id: 'United States',
		label: 'united-states',
		dateFormat: 'mm-dd-yyyy'
	},
	{
		id: 'Uruguay',
		label: 'uruguay'
	},
	{
		id: 'Uzbekistan',
		label: 'uzbekistan',
		dateFormat: 'yyyy-mm-dd'
	},
	{
		id: 'Vanuatu',
		label: 'vanuatu'
	},
	{
		id: 'Venezuela, Bolivarian Republic of',
		label: 'venezuela,-bolivarian-republic-of'
	},
	{
		id: 'Viet Nam',
		label: 'vietnam',
		dateFormat: 'dd/mm/yyyy'
	},
	{
		id: 'Virgin Islands, British',
		label: 'british-virgin-islands'
	},
	{
		id: 'Wallis and Futuna',
		label: 'wallis-and-futuna'
	},
	{
		id: 'Western Sahara',
		label: 'western-sahara'
	},
	{
		id: 'Yemen',
		label: 'yemen'
	},
	{
		id: 'Zambia',
		label: 'zambia'
	},
	{
		id: 'Zimbabwe',
		label: 'zimbabwe'
	}
];

export const TIMEZONE = [
	{
		id: 'Africa/Abidjan',
		label: 'africa-abidjan'
	},
	{
		id: 'Africa/Accra',
		label: 'africa-accra'
	},
	{
		id: 'Africa/Addis_Ababa',
		label: 'africa-addis-ababa'
	},
	{
		id: 'Africa/Algiers',
		label: 'africa-algiers'
	},
	{
		id: 'Africa/Asmera',
		label: 'africa-asmera'
	},
	{
		id: 'Africa/Bamako',
		label: 'africa-bamako'
	},
	{
		id: 'Africa/Bangui',
		label: 'africa-bangui'
	},
	{
		id: 'Africa/Banjul',
		label: 'africa-banjul'
	},
	{
		id: 'Africa/Bissau',
		label: 'africa-bissau'
	},
	{
		id: 'Africa/Blantyre',
		label: 'africa-blantyre'
	},
	{
		id: 'Africa/Brazzaville',
		label: 'africa-brazzaville'
	},
	{
		id: 'Africa/Bujumbura',
		label: 'africa-bujumbura'
	},
	{
		id: 'Africa/Cairo',
		label: 'africa-cairo'
	},
	{
		id: 'Africa/Casablanca',
		label: 'africa-casablanca'
	},
	{
		id: 'Africa/Ceuta',
		label: 'africa-ceuta'
	},
	{
		id: 'Africa/Conakry',
		label: 'africa-conakry'
	},
	{
		id: 'Africa/Dakar',
		label: 'africa-dakar'
	},
	{
		id: 'Africa/Dar_es_Salaam',
		label: 'africa-dar-es-salaam'
	},
	{
		id: 'Africa/Djibouti',
		label: 'africa-djibouti'
	},
	{
		id: 'Africa/Douala',
		label: 'africa-douala'
	},
	{
		id: 'Africa/El_Aaiun',
		label: 'africa-el-aaiun'
	},
	{
		id: 'Africa/Freetown',
		label: 'africa-freetown'
	},
	{
		id: 'Africa/Gaborone',
		label: 'africa-gaborone'
	},
	{
		id: 'Africa/Harare',
		label: 'africa-harare'
	},
	{
		id: 'Africa/Johannesburg',
		label: 'africa-johannesburg'
	},
	{
		id: 'Africa/Juba',
		label: 'africa-juba'
	},
	{
		id: 'Africa/Kampala',
		label: 'africa-kampala'
	},
	{
		id: 'Africa/Khartoum',
		label: 'africa-khartoum'
	},
	{
		id: 'Africa/Kigali',
		label: 'africa-kigali'
	},
	{
		id: 'Africa/Kinshasa',
		label: 'africa-kinshasa'
	},
	{
		id: 'Africa/Lagos',
		label: 'africa-lagos'
	},
	{
		id: 'Africa/Libreville',
		label: 'africa-libreville'
	},
	{
		id: 'Africa/Lome',
		label: 'africa-lome'
	},
	{
		id: 'Africa/Luanda',
		label: 'africa-luanda'
	},
	{
		id: 'Africa/Lubumbashi',
		label: 'africa-lubumbashi'
	},
	{
		id: 'Africa/Lusaka',
		label: 'africa-lusaka'
	},
	{
		id: 'Africa/Malabo',
		label: 'africa-malabo'
	},
	{
		id: 'Africa/Maputo',
		label: 'africa-maputo'
	},
	{
		id: 'Africa/Maseru',
		label: 'africa-maseru'
	},
	{
		id: 'Africa/Mbabane',
		label: 'africa-mbabane'
	},
	{
		id: 'Africa/Mogadishu',
		label: 'africa-mogadishu'
	},
	{
		id: 'Africa/Monrovia',
		label: 'africa-monrovia'
	},
	{
		id: 'Africa/Nairobi',
		label: 'africa-nairobi'
	},
	{
		id: 'Africa/Ndjamena',
		label: 'africa-ndjamena'
	},
	{
		id: 'Africa/Niamey',
		label: 'africa-niamey'
	},
	{
		id: 'Africa/Nouakchott',
		label: 'africa-nouakchott'
	},
	{
		id: 'Africa/Ouagadougou',
		label: 'africa-ouagadougou'
	},
	{
		id: 'Africa/Porto-Novo',
		label: 'africa-porto-novo'
	},
	{
		id: 'Africa/Sao_Tome',
		label: 'africa-sao-tome'
	},
	{
		id: 'Africa/Tripoli',
		label: 'africa-tripoli'
	},
	{
		id: 'Africa/Tunis',
		label: 'africa-tunis'
	},
	{
		id: 'Africa/Windhoek',
		label: 'africa-windhoek'
	},
	{
		id: 'America/Adak',
		label: 'america-adak'
	},
	{
		id: 'America/Anchorage',
		label: 'america-anchorage'
	},
	{
		id: 'America/Anguilla',
		label: 'america-anguilla'
	},
	{
		id: 'America/Antigua',
		label: 'america-antigua'
	},
	{
		id: 'America/Araguaina',
		label: 'america-araguaina'
	},
	{
		id: 'America/Argentina/Buenos_Aires',
		label: 'america-argentina-buenos-aires'
	},
	{
		id: 'America/Argentina/La_Rioja',
		label: 'america-argentina-la-rioja'
	},
	{
		id: 'America/Argentina/Rio_Gallegos',
		label: 'america-argentina-rio-gallegos'
	},
	{
		id: 'America/Argentina/Salta',
		label: 'america-argentina-salta'
	},
	{
		id: 'America/Argentina/San_Juan',
		label: 'america-argentina-san-juan'
	},
	{
		id: 'America/Argentina/San_Luis',
		label: 'america-argentina-san-luis'
	},
	{
		id: 'America/Argentina/Tucuman',
		label: 'america-argentina-tucuman'
	},
	{
		id: 'America/Argentina/Ushuaia',
		label: 'america-argentina-ushuaia'
	},
	{
		id: 'America/Aruba',
		label: 'america-aruba'
	},
	{
		id: 'America/Asuncion',
		label: 'america-asuncion'
	},
	{
		id: 'America/Bahia',
		label: 'america-bahia'
	},
	{
		id: 'America/Bahia_Banderas',
		label: 'america-bahia-banderas'
	},
	{
		id: 'America/Barbados',
		label: 'america-barbados'
	},
	{
		id: 'America/Belem',
		label: 'america-belem'
	},
	{
		id: 'America/Belize',
		label: 'america-belize'
	},
	{
		id: 'America/Blanc-Sablon',
		label: 'america-blanc-sablon'
	},
	{
		id: 'America/Boa_Vista',
		label: 'america-boa-vista'
	},
	{
		id: 'America/Bogota',
		label: 'america-bogota'
	},
	{
		id: 'America/Boise',
		label: 'america-boise'
	},
	{
		id: 'America/Cambridge_Bay',
		label: 'america-cambridge-bay'
	},
	{
		id: 'America/Campo_Grande',
		label: 'america-campo-grande'
	},
	{
		id: 'America/Cancun',
		label: 'america-cancun'
	},
	{
		id: 'America/Caracas',
		label: 'america-caracas'
	},
	{
		id: 'America/Catamarca',
		label: 'america-catamarca'
	},
	{
		id: 'America/Cayenne',
		label: 'america-cayenne'
	},
	{
		id: 'America/Cayman',
		label: 'america-cayman'
	},
	{
		id: 'America/Chicago',
		label: 'america-chicago'
	},
	{
		id: 'America/Chihuahua',
		label: 'america-chihuahua'
	},
	{
		id: 'America/Coral_Harbour',
		label: 'america-coral-harbour'
	},
	{
		id: 'America/Cordoba',
		label: 'america-cordoba'
	},
	{
		id: 'America/Costa_Rica',
		label: 'america-costa-rica'
	},
	{
		id: 'America/Creston',
		label: 'america-creston'
	},
	{
		id: 'America/Cuiaba',
		label: 'america-cuiaba'
	},
	{
		id: 'America/Curacao',
		label: 'america-curacao'
	},
	{
		id: 'America/Danmarkshavn',
		label: 'america-danmarkshavn'
	},
	{
		id: 'America/Dawso..n',
		label: 'america-dawso--n'
	},
	{
		id: 'America/Dawson_Creek',
		label: 'america-dawson-creek'
	},
	{
		id: 'America/Denver',
		label: 'america-denver'
	},
	{
		id: 'America/Detroit',
		label: 'america-detroit'
	},
	{
		id: 'America/Dominica',
		label: 'america-dominica'
	},
	{
		id: 'America/Edmonton',
		label: 'america-edmonton'
	},
	{
		id: 'America/Eirunepe',
		label: 'america-eirunepe'
	},
	{
		id: 'America/El_Salvador',
		label: 'america-el-salvador'
	},
	{
		id: 'America/Fort_Nelson',
		label: 'america-fort-nelson'
	},
	{
		id: 'America/Fortaleza',
		label: 'america-fortaleza'
	},
	{
		id: 'America/Glace_Bay',
		label: 'america-glace-bay'
	},
	{
		id: 'America/Godthab',
		label: 'america-godthab'
	},
	{
		id: 'America/Goose_Bay',
		label: 'america-goose-bay'
	},
	{
		id: 'America/Grand_Turk',
		label: 'america-grand-turk'
	},
	{
		id: 'America/Grenada',
		label: 'america-grenada'
	},
	{
		id: 'America/Guadeloupe',
		label: 'america-guadeloupe'
	},
	{
		id: 'America/Guatemala',
		label: 'america-guatemala'
	},
	{
		id: 'America/Guayaquil',
		label: 'america-guayaquil'
	},
	{
		id: 'America/Guyana',
		label: 'america-guyana'
	},
	{
		id: 'America/Halifax',
		label: 'america-halifax'
	},
	{
		id: 'America/Havana',
		label: 'america-havana'
	},
	{
		id: 'America/Hermosillo',
		label: 'america-hermosillo'
	},
	{
		id: 'America/Indiana/Indianapolis',
		label: 'america-indiana-indianapolis'
	},
	{
		id: 'America/Indiana/Knox',
		label: 'america-indiana-knox'
	},
	{
		id: 'America/Indiana/Marengo',
		label: 'america-indiana-marengo'
	},
	{
		id: 'America/Indiana/Petersburg',
		label: 'america-indiana-petersburg'
	},
	{
		id: 'America/Indiana/Tell_City',
		label: 'america-indiana-tell-city'
	},
	{
		id: 'America/Indiana/Vevay',
		label: 'america-indiana-vevay'
	},
	{
		id: 'America/Indiana/Vincennes',
		label: 'america-indiana-vincennes'
	},
	{
		id: 'America/Indiana/Winamac',
		label: 'america-indiana-winamac'
	},
	{
		id: 'America/Inuvik',
		label: 'america-inuvik'
	},
	{
		id: 'America/Iqaluit',
		label: 'america-iqaluit'
	},
	{
		id: 'America/Jamaica',
		label: 'america-jamaica'
	},
	{
		id: 'America/Jujuy',
		label: 'america-jujuy'
	},
	{
		id: 'America/Juneau',
		label: 'america-juneau'
	},
	{
		id: 'America/Kentucky/Monticello',
		label: 'america-kentucky-monticello'
	},
	{
		id: 'America/Kralendijk',
		label: 'america-kralendijk'
	},
	{
		id: 'America/La_Paz',
		label: 'america-la-paz'
	},
	{
		id: 'America/Lima',
		label: 'america-lima'
	},
	{
		id: 'America/Los_Angeles',
		label: 'america-los-angeles'
	},
	{
		id: 'America/Louisville',
		label: 'america-louisville'
	},
	{
		id: 'America/Lower_Princes',
		label: 'america-lower-princes'
	},
	{
		id: 'America/Maceio',
		label: 'america-maceio'
	},
	{
		id: 'America/Managua',
		label: 'america-managua'
	},
	{
		id: 'America/Manaus',
		label: 'america-manaus'
	},
	{
		id: 'America/Marigot',
		label: 'america-marigot'
	},
	{
		id: 'America/Martinique',
		label: 'america-martinique'
	},
	{
		id: 'America/Matamoros',
		label: 'america-matamoros'
	},
	{
		id: 'America/Mazatlan',
		label: 'america-mazatlan'
	},
	{
		id: 'America/Mendoza',
		label: 'america-mendoza'
	},
	{
		id: 'America/Menominee',
		label: 'america-menominee'
	},
	{
		id: 'America/Merida',
		label: 'america-merida'
	},
	{
		id: 'America/Metlakatla',
		label: 'america-metlakatla'
	},
	{
		id: 'America/Mexico_City',
		label: 'america-mexico-city'
	},
	{
		id: 'America/Miquelon',
		label: 'america-miquelon'
	},
	{
		id: 'America/Moncton',
		label: 'america-moncton'
	},
	{
		id: 'America/Monterrey',
		label: 'america-monterrey'
	},
	{
		id: 'America/Montevideo',
		label: 'america-montevideo'
	},
	{
		id: 'America/Montreal',
		label: 'america-montreal'
	},
	{
		id: 'America/Montserrat',
		label: 'america-montserrat'
	},
	{
		id: 'America/Nassau',
		label: 'america-nassau'
	},
	{
		id: 'America/New_York',
		label: 'america-new-york'
	},
	{
		id: 'America/Nipigon',
		label: 'america-nipigon'
	},
	{
		id: 'America/Nome',
		label: 'america-nome'
	},
	{
		id: 'America/Noronha',
		label: 'america-noronha'
	},
	{
		id: 'America/North_Dakota/Beulah',
		label: 'america-north-dakota-beulah'
	},
	{
		id: 'America/North_Dakota/Center',
		label: 'america-north-dakota-center'
	},
	{
		id: 'America/North_Dakota/New_Salem',
		label: 'america-north-dakota-new-salem'
	},
	{
		id: 'America/Ojinaga',
		label: 'america-ojinaga'
	},
	{
		id: 'America/Panama',
		label: 'america-panama'
	},
	{
		id: 'America/Pangnirtung',
		label: 'america-pangnirtung'
	},
	{
		id: 'America/Paramaribo',
		label: 'america-paramaribo'
	},
	{
		id: 'America/Phoenix',
		label: 'america-phoenix'
	},
	{
		id: 'America/Port-au-Prince',
		label: 'america-port-au-prince'
	},
	{
		id: 'America/Port_of_Spain',
		label: 'america-port-of-spain'
	},
	{
		id: 'America/Porto_Velho',
		label: 'america-porto-velho'
	},
	{
		id: 'America/Puerto_Rico',
		label: 'america-puerto-rico'
	},
	{
		id: 'America/Punta_Arenas',
		label: 'america-punta-arenas'
	},
	{
		id: 'America/Rainy_River',
		label: 'america-rainy-river'
	},
	{
		id: 'America/Rankin_Inlet',
		label: 'america-rankin-inlet'
	},
	{
		id: 'America/Recife',
		label: 'america-recife'
	},
	{
		id: 'America/Regina',
		label: 'america-regina'
	},
	{
		id: 'America/Resolute',
		label: 'america-resolute'
	},
	{
		id: 'America/Rio_Branco',
		label: 'america-rio-branco'
	},
	{
		id: 'America/Santa_Isabel',
		label: 'america-santa-isabel'
	},
	{
		id: 'America/Santarem',
		label: 'america-santarem'
	},
	{
		id: 'America/Santiago',
		label: 'america-santiago'
	},
	{
		id: 'America/Santo_Domingo',
		label: 'america-santo-domingo'
	},
	{
		id: 'America/Sao_Paulo',
		label: 'america-sao-paulo'
	},
	{
		id: 'America/Scoresbysund',
		label: 'america-scoresbysund'
	},
	{
		id: 'America/Sitka',
		label: 'america-sitka'
	},
	{
		id: 'America/St_Barthelemy',
		label: 'america-st-barthelemy'
	},
	{
		id: 'America/St_Johns',
		label: 'america-st-johns'
	},
	{
		id: 'America/St_Kitts',
		label: 'america-st-kitts'
	},
	{
		id: 'America/St_Lucia',
		label: 'america-st-lucia'
	},
	{
		id: 'America/St_Thomas',
		label: 'america-st-thomas'
	},
	{
		id: 'America/St_Vincent',
		label: 'america-st-vincent'
	},
	{
		id: 'America/Swift_Current',
		label: 'america-swift-current'
	},
	{
		id: 'America/Tegucigalpa',
		label: 'america-tegucigalpa'
	},
	{
		id: 'America/Thule',
		label: 'america-thule'
	},
	{
		id: 'America/Thunder_Bay',
		label: 'america-thunder-bay'
	},
	{
		id: 'America/Tijuana',
		label: 'america-tijuana'
	},
	{
		id: 'America/Toronto',
		label: 'america-toronto'
	},
	{
		id: 'America/Tortola',
		label: 'america-tortola'
	},
	{
		id: 'America/Vancouver',
		label: 'america-vancouver'
	},
	{
		id: 'America/Whitehorse',
		label: 'america-whitehorse'
	},
	{
		id: 'America/Winnipeg',
		label: 'america-winnipeg'
	},
	{
		id: 'America/Yakutat',
		label: 'america-yakutat'
	},
	{
		id: 'America/Yellowknife',
		label: 'america-yellowknife'
	},
	{
		id: 'Antarctica/Casey',
		label: 'antarctica-casey'
	},
	{
		id: 'Antarctica/Davis',
		label: 'antarctica-davis'
	},
	{
		id: 'Antarctica/DumontDUrville',
		label: 'antarctica-dumontdurville'
	},
	{
		id: 'Antarctica/Macquarie',
		label: 'antarctica-macquarie'
	},
	{
		id: 'Antarctica/Mawson',
		label: 'antarctica-mawson'
	},
	{
		id: 'Antarctica/McMurdo',
		label: 'antarctica-mcmurdo'
	},
	{
		id: 'Antarctica/Palmer',
		label: 'antarctica-palmer'
	},
	{
		id: 'Antarctica/Rothera',
		label: 'antarctica-rothera'
	},
	{
		id: 'Antarctica/Syowa',
		label: 'antarctica-syowa'
	},
	{
		id: 'Antarctica/Troll',
		label: 'antarctica-troll'
	},
	{
		id: 'Antarctica/Vostok',
		label: 'antarctica-vostok'
	},
	{
		id: 'Arctic/Longyearbyen',
		label: 'arctic-longyearbyen'
	},
	{
		id: 'Asia/Aden',
		label: 'asia-aden'
	},
	{
		id: 'Asia/Almaty',
		label: 'asia-almaty'
	},
	{
		id: 'Asia/Amman',
		label: 'asia-amman'
	},
	{
		id: 'Asia/Anadyr',
		label: 'asia-anadyr'
	},
	{
		id: 'Asia/Aqtau',
		label: 'asia-aqtau'
	},
	{
		id: 'Asia/Aqtobe',
		label: 'asia-aqtobe'
	},
	{
		id: 'Asia/Ashgabat',
		label: 'asia-ashgabat'
	},
	{
		id: 'Asia/Atyrau',
		label: 'asia-atyrau'
	},
	{
		id: 'Asia/Baghdad',
		label: 'asia-baghdad'
	},
	{
		id: 'Asia/Bahrain',
		label: 'asia-bahrain'
	},
	{
		id: 'Asia/Baku',
		label: 'asia-baku'
	},
	{
		id: 'Asia/Bangkok',
		label: 'asia-bangkok'
	},
	{
		id: 'Asia/Barnaul',
		label: 'asia-barnaul'
	},
	{
		id: 'Asia/Beirut',
		label: 'asia-beirut'
	},
	{
		id: 'Asia/Bishkek',
		label: 'asia-bishkek'
	},
	{
		id: 'Asia/Brunei',
		label: 'asia-brunei'
	},
	{
		id: 'Asia/Chita',
		label: 'asia-chita'
	},
	{
		id: 'Asia/Choibalsan',
		label: 'asia-choibalsan'
	},
	{
		id: 'Asia/Colombo',
		label: 'asia-colombo'
	},
	{
		id: 'Asia/Damascus',
		label: 'asia-damascus'
	},
	{
		id: 'Asia/Dhaka',
		label: 'asia-dhaka'
	},
	{
		id: 'Asia/Dili',
		label: 'asia-dili'
	},
	{
		id: 'Asia/Dubai',
		label: 'asia-dubai'
	},
	{
		id: 'Asia/Dushanbe',
		label: 'asia-dushanbe'
	},
	{
		id: 'Asia/Famagusta',
		label: 'asia-famagusta'
	},
	{
		id: 'Asia/Gaza',
		label: 'asia-gaza'
	},
	{
		id: 'Asia/Hebron',
		label: 'asia-hebron'
	},
	{
		id: 'Asia/Ho_Chi_Minh',
		label: 'asia-ho-chi-minh'
	},
	{
		id: 'Asia/Hong_Kong',
		label: 'asia-hong-kong'
	},
	{
		id: 'Asia/Hovd',
		label: 'asia-hovd'
	},
	{
		id: 'Asia/Irkutsk',
		label: 'asia-irkutsk'
	},
	{
		id: 'Asia/Jakarta',
		label: 'asia-jakarta'
	},
	{
		id: 'Asia/Jayapura',
		label: 'asia-jayapura'
	},
	{
		id: 'Asia/Jerusalem',
		label: 'asia-jerusalem'
	},
	{
		id: 'Asia/Kabul',
		label: 'asia-kabul'
	},
	{
		id: 'Asia/Kamchatka',
		label: 'asia-kamchatka'
	},
	{
		id: 'Asia/Karachi',
		label: 'asia-karachi'
	},
	{
		id: 'Asia/Kathmandu',
		label: 'asia-kathmandu'
	},
	{
		id: 'Asia/Khandyga',
		label: 'asia-khandyga'
	},
	{
		id: 'Asia/Kolkata',
		label: 'asia-kolkata'
	},
	{
		id: 'Asia/Krasnoyarsk',
		label: 'asia-krasnoyarsk'
	},
	{
		id: 'Asia/Kuala_Lumpur',
		label: 'asia-kuala-lumpur'
	},
	{
		id: 'Asia/Kuching',
		label: 'asia-kuching'
	},
	{
		id: 'Asia/Kuwait',
		label: 'asia-kuwait'
	},
	{
		id: 'Asia/Macau',
		label: 'asia-macau'
	},
	{
		id: 'Asia/Magadan',
		label: 'asia-magadan'
	},
	{
		id: 'Asia/Makassar',
		label: 'asia-makassar'
	},
	{
		id: 'Asia/Manila',
		label: 'asia-manila'
	},
	{
		id: 'Asia/Muscat',
		label: 'asia-muscat'
	},
	{
		id: 'Asia/Nicosia',
		label: 'asia-nicosia'
	},
	{
		id: 'Asia/Novokuznetsk',
		label: 'asia-novokuznetsk'
	},
	{
		id: 'Asia/Novosibirsk',
		label: 'asia-novosibirsk'
	},
	{
		id: 'Asia/Omsk',
		label: 'asia-omsk'
	},
	{
		id: 'Asia/Oral',
		label: 'asia-oral'
	},
	{
		id: 'Asia/Phnom_Penh',
		label: 'asia-phnom-penh'
	},
	{
		id: 'Asia/Pontianak',
		label: 'asia-pontianak'
	},
	{
		id: 'Asia/Pyongyang',
		label: 'asia-pyongyang'
	},
	{
		id: 'Asia/Qatar',
		label: 'asia-qatar'
	},
	{
		id: 'Asia/Qostanay',
		label: 'asia-qostanay'
	},
	{
		id: 'Asia/Qyzylorda',
		label: 'asia-qyzylorda'
	},
	{
		id: 'Asia/Rangoon',
		label: 'asia-rangoon'
	},
	{
		id: 'Asia/Riyadh',
		label: 'asia-riyadh'
	},
	{
		id: 'Asia/Sakhalin',
		label: 'asia-sakhalin'
	},
	{
		id: 'Asia/Samarkand',
		label: 'asia-samarkand'
	},
	{
		id: 'Asia/Seoul',
		label: 'asia-seoul'
	},
	{
		id: 'Asia/Shanghai',
		label: 'asia-shanghai'
	},
	{
		id: 'Asia/Singapore',
		label: 'asia-singapore'
	},
	{
		id: 'Asia/Srednekolymsk',
		label: 'asia-srednekolymsk'
	},
	{
		id: 'Asia/Taipei',
		label: 'asia-taipei'
	},
	{
		id: 'Asia/Tashkent',
		label: 'asia-tashkent'
	},
	{
		id: 'Asia/Tbilisi',
		label: 'asia-tbilisi'
	},
	{
		id: 'Asia/Tehran',
		label: 'asia-tehran'
	},
	{
		id: 'Asia/Thimphu',
		label: 'asia-thimphu'
	},
	{
		id: 'Asia/Tokyo',
		label: 'asia-tokyo'
	},
	{
		id: 'Asia/Tomsk',
		label: 'asia-tomsk'
	},
	{
		id: 'Asia/Ulaanbaatar',
		label: 'asia-ulaanbaatar'
	},
	{
		id: 'Asia/Urumqi',
		label: 'asia-urumqi'
	},
	{
		id: 'Asia/Ust-Nera',
		label: 'asia-ust-nera'
	},
	{
		id: 'Asia/Vientiane',
		label: 'asia-vientiane'
	},
	{
		id: 'Asia/Vladivostok',
		label: 'asia-vladivostok'
	},
	{
		id: 'Asia/Yakutsk',
		label: 'asia-yakutsk'
	},
	{
		id: 'Asia/Yekaterinburg',
		label: 'asia-yekaterinburg'
	},
	{
		id: 'Asia/Yerevan',
		label: 'asia-yerevan'
	},
	{
		id: 'Atlantic/Azores',
		label: 'atlantic-azores'
	},
	{
		id: 'Atlantic/Bermuda',
		label: 'atlantic-bermuda'
	},
	{
		id: 'Atlantic/Canary',
		label: 'atlantic-canary'
	},
	{
		id: 'Atlantic/Cape_Verde',
		label: 'atlantic-cape-verde'
	},
	{
		id: 'Atlantic/Faeroe',
		label: 'atlantic-faeroe'
	},
	{
		id: 'Atlantic/Madeira',
		label: 'atlantic-madeira'
	},
	{
		id: 'Atlantic/Reykjavik',
		label: 'atlantic-reykjavik'
	},
	{
		id: 'Atlantic/South_Georgia',
		label: 'atlantic-south-georgia'
	},
	{
		id: 'Atlantic/St_Helena',
		label: 'atlantic-st-helena'
	},
	{
		id: 'Atlantic/Stanley',
		label: 'atlantic-stanley'
	},
	{
		id: 'Australia/Adelaide',
		label: 'australia-adelaide'
	},
	{
		id: 'Australia/Brisbane',
		label: 'australia-brisbane'
	},
	{
		id: 'Australia/Broken_Hill',
		label: 'australia-broken-hill'
	},
	{
		id: 'Australia/Currie',
		label: 'australia-currie'
	},
	{
		id: 'Australia/Darwin',
		label: 'australia-darwin'
	},
	{
		id: 'Australia/Eucla',
		label: 'australia-eucla'
	},
	{
		id: 'Australia/Hobart',
		label: 'australia-hobart'
	},
	{
		id: 'Australia/Lindeman',
		label: 'australia-lindeman'
	},
	{
		id: 'Australia/Lord_Howe',
		label: 'australia-lord-howe'
	},
	{
		id: 'Australia/Melbourne',
		label: 'australia-melbourne'
	},
	{
		id: 'Australia/Perth',
		label: 'australia-perth'
	},
	{
		id: 'Australia/Sydney',
		label: 'australia-sydney'
	},
	{
		id: 'Europe/Amsterdam',
		label: 'europe-amsterdam'
	},
	{
		id: 'Europe/Andorra',
		label: 'europe-andorra'
	},
	{
		id: 'Europe/Astrakhan',
		label: 'europe-astrakhan'
	},
	{
		id: 'Europe/Athens',
		label: 'europe-athens'
	},
	{
		id: 'Europe/Belgrade',
		label: 'europe-belgrade'
	},
	{
		id: 'Europe/Berlin',
		label: 'europe-berlin'
	},
	{
		id: 'Europe/Bratislava',
		label: 'europe-bratislava'
	},
	{
		id: 'Europe/Brussels',
		label: 'europe-brussels'
	},
	{
		id: 'Europe/Bucharest',
		label: 'europe-bucharest'
	},
	{
		id: 'Europe/Budapest',
		label: 'europe-budapest'
	},
	{
		id: 'Europe/Busingen',
		label: 'europe-busingen'
	},
	{
		id: 'Europe/Chisinau',
		label: 'europe-chisinau'
	},
	{
		id: 'Europe/Copenhagen',
		label: 'europe-copenhagen'
	},
	{
		id: 'Europe/Dublin',
		label: 'europe-dublin'
	},
	{
		id: 'Europe/Gibraltar',
		label: 'europe-gibraltar'
	},
	{
		id: 'Europe/Guernsey',
		label: 'europe-guernsey'
	},
	{
		id: 'Europe/Helsinki',
		label: 'europe-helsinki'
	},
	{
		id: 'Europe/Isle_of_Man',
		label: 'europe-isle-of-man'
	},
	{
		id: 'Europe/Istanbul',
		label: 'europe-istanbul'
	},
	{
		id: 'Europe/Jersey',
		label: 'europe-jersey'
	},
	{
		id: 'Europe/Kaliningrad',
		label: 'europe-kaliningrad'
	},
	{
		id: 'Europe/Kirov',
		label: 'europe-kirov'
	},
	{
		id: 'Europe/Kyiv',
		label: 'europe-kyiv'
	},
	{
		id: 'Europe/Lisbon',
		label: 'europe-lisbon'
	},
	{
		id: 'Europe/Ljubljana',
		label: 'europe-ljubljana'
	},
	{
		id: 'Europe/London',
		label: 'europe-london'
	},
	{
		id: 'Europe/Luxembourg',
		label: 'europe-luxembourg'
	},
	{
		id: 'Europe/Madrid',
		label: 'europe-madrid'
	},
	{
		id: 'Europe/Malta',
		label: 'europe-malta'
	},
	{
		id: 'Europe/Mariehamn',
		label: 'europe-mariehamn'
	},
	{
		id: 'Europe/Minsk',
		label: 'europe-minsk'
	},
	{
		id: 'Europe/Monaco',
		label: 'europe-monaco'
	},
	{
		id: 'Europe/Moscow',
		label: 'europe-moscow'
	},
	{
		id: 'Europe/Oslo',
		label: 'europe-oslo'
	},
	{
		id: 'Europe/Paris',
		label: 'europe-paris'
	},
	{
		id: 'Europe/Podgorica',
		label: 'europe-podgorica'
	},
	{
		id: 'Europe/Prague',
		label: 'europe-prague'
	},
	{
		id: 'Europe/Riga',
		label: 'europe-riga'
	},
	{
		id: 'Europe/Rome',
		label: 'europe-rome'
	},
	{
		id: 'Europe/Samara',
		label: 'europe-samara'
	},
	{
		id: 'Europe/San_Marino',
		label: 'europe-san-marino'
	},
	{
		id: 'Europe/Sarajevo',
		label: 'europe-sarajevo'
	},
	{
		id: 'Europe/Saratov',
		label: 'europe-saratov'
	},
	{
		id: 'Europe/Simferopol',
		label: 'europe-simferopol'
	},
	{
		id: 'Europe/Skopje',
		label: 'europe-skopje'
	},
	{
		id: 'Europe/Sofia',
		label: 'europe-sofia'
	},
	{
		id: 'Europe/Stockholm',
		label: 'europe-stockholm'
	},
	{
		id: 'Europe/Tallinn',
		label: 'europe-tallinn'
	},
	{
		id: 'Europe/Tirane',
		label: 'europe-tirane'
	},
	{
		id: 'Europe/Ulyanovsk',
		label: 'europe-ulyanovsk'
	},
	{
		id: 'Europe/Uzhgorod',
		label: 'europe-uzhgorod'
	},
	{
		id: 'Europe/Vaduz',
		label: 'europe-vaduz'
	},
	{
		id: 'Europe/Vatican',
		label: 'europe-vatican'
	},
	{
		id: 'Europe/Vienna',
		label: 'europe-vienna'
	},
	{
		id: 'Europe/Vilnius',
		label: 'europe-vilnius'
	},
	{
		id: 'Europe/Volgograd',
		label: 'europe-volgograd'
	},
	{
		id: 'Europe/Warsaw',
		label: 'europe-warsaw'
	},
	{
		id: 'Europe/Zagreb',
		label: 'europe-zagreb'
	},
	{
		id: 'Europe/Zaporozhye',
		label: 'europe-zaporozhye'
	},
	{
		id: 'Europe/Zurich',
		label: 'europe-zurich'
	},
	{
		id: 'GMT',
		label: 'gmt'
	},
	{
		id: 'Indian/Antananarivo',
		label: 'indian-antananarivo'
	},
	{
		id: 'Indian/Chagos',
		label: 'indian-chagos'
	},
	{
		id: 'Indian/Christmas',
		label: 'indian-christmas'
	},
	{
		id: 'Indian/Cocos',
		label: 'indian-cocos'
	},
	{
		id: 'Indian/Comoro',
		label: 'indian-comoro'
	},
	{
		id: 'Indian/Kerguelen',
		label: 'indian-kerguelen'
	},
	{
		id: 'Indian/Mahe',
		label: 'indian-mahe'
	},
	{
		id: 'Indian/Maldives',
		label: 'indian-maldives'
	},
	{
		id: 'Indian/Mauritius',
		label: 'indian-mauritius'
	},
	{
		id: 'Indian/Mayotte',
		label: 'indian-mayotte'
	},
	{
		id: 'Indian/Reunion',
		label: 'indian-reunion'
	},
	{
		id: 'Pacific/Apia',
		label: 'pacific-apia'
	},
	{
		id: 'Pacific/Auckland',
		label: 'pacific-auckland'
	},
	{
		id: 'Pacific/Bougainville',
		label: 'pacific-bougainville'
	},
	{
		id: 'Pacific/Chatham',
		label: 'pacific-chatham'
	},
	{
		id: 'Pacific/Easter',
		label: 'pacific-easter'
	},
	{
		id: 'Pacific/Efate',
		label: 'pacific-efate'
	},
	{
		id: 'Pacific/Enderbury',
		label: 'pacific-enderbury'
	},
	{
		id: 'Pacific/Fakaofo',
		label: 'pacific-fakaofo'
	},
	{
		id: 'Pacific/Fiji',
		label: 'pacific-fiji'
	},
	{
		id: 'Pacific/Funafuti',
		label: 'pacific-funafuti'
	},
	{
		id: 'Pacific/Galapagos',
		label: 'pacific-galapagos'
	},
	{
		id: 'Pacific/Gambier',
		label: 'pacific-gambier'
	},
	{
		id: 'Pacific/Guadalcanal',
		label: 'pacific-guadalcanal'
	},
	{
		id: 'Pacific/Guam',
		label: 'pacific-guam'
	},
	{
		id: 'Pacific/Honolulu',
		label: 'pacific-honolulu'
	},
	{
		id: 'Pacific/Johnston',
		label: 'pacific-johnston'
	},
	{
		id: 'Pacific/Kiritimati',
		label: 'pacific-kiritimati'
	},
	{
		id: 'Pacific/Kosrae',
		label: 'pacific-kosrae'
	},
	{
		id: 'Pacific/Kwajalein',
		label: 'pacific-kwajalein'
	},
	{
		id: 'Pacific/Majuro',
		label: 'pacific-majuro'
	},
	{
		id: 'Pacific/Marquesas',
		label: 'pacific-marquesas'
	},
	{
		id: 'Pacific/Midway',
		label: 'pacific-midway'
	},
	{
		id: 'Pacific/Nauru',
		label: 'pacific-nauru'
	},
	{
		id: 'Pacific/Niue',
		label: 'pacific-niue'
	},
	{
		id: 'Pacific/Norfolk',
		label: 'pacific-norfolk'
	},
	{
		id: 'Pacific/Noumea',
		label: 'pacific-noumea'
	},
	{
		id: 'Pacific/Pago_Pago',
		label: 'pacific-pago-pago'
	},
	{
		id: 'Pacific/Palau',
		label: 'pacific-palau'
	},
	{
		id: 'Pacific/Pitcairn',
		label: 'pacific-pitcairn'
	},
	{
		id: 'Pacific/Ponape',
		label: 'pacific-ponape'
	},
	{
		id: 'Pacific/Port_Moresby',
		label: 'pacific-port-moresby'
	},
	{
		id: 'Pacific/Rarotonga',
		label: 'pacific-rarotonga'
	},
	{
		id: 'Pacific/Saipan',
		label: 'pacific-saipan'
	},
	{
		id: 'Pacific/Tahiti',
		label: 'pacific-tahiti'
	},
	{
		id: 'Pacific/Tarawa',
		label: 'pacific-tarawa'
	},
	{
		id: 'Pacific/Tongatapu',
		label: 'pacific-tongatapu'
	},
	{
		id: 'Pacific/Truk',
		label: 'pacific-truk'
	},
	{
		id: 'Pacific/Wake',
		label: 'pacific-wake'
	},
	{
		id: 'Pacific/Wallis',
		label: 'pacific-wallis'
	}
];
