export enum ChannelGroup {
	AecReference = 'AEC_REFERENCE',
	AecReferenceMixOutput = 'AEC_REFERENCE_MIX_OUTPUT',
	Analog70VOutput = 'ANALOG_70V_OUTPUT',
	AnalogAuxInput = 'ANALOG_AUX_INPUT',
	AnalogInput = 'ANALOG_INPUT',
	AnalogOutput = 'ANALOG_OUTPUT',
	Automix = 'AUTOMIX',
	AuxInput = 'AUX_INPUT',
	AuxOutput = 'AUX_OUTPUT',
	Back = 'BACK',
	BluetoothInput = 'BLUETOOTH_INPUT',
	BluetoothOutput = 'BLUETOOTH_OUTPUT',
	DanteAnalogInput = 'DANTE_ANALOG_INPUT',
	DanteAuxInput = 'DANTE_AUX_INPUT',
	DanteInput = 'DANTE_INPUT',
	DanteLineInput = 'DANTE_LINE_INPUT',
	DanteLineOutput = 'DANTE_LINE_OUTPUT',
	DanteMicInput = 'DANTE_MIC_INPUT',
	DanteOutput = 'DANTE_OUTPUT',
	DirectOutput = 'DIRECT_OUTPUT',
	DirectOutputAnalogInput = 'DIRECT_OUTPUT_ANALOG_INPUT',
	Generic = 'GENERIC',
	Headphone = 'HEADPHONE',
	Lobe = 'LOBE',
	Loudspeaker = 'LOUDSPEAKER',
	Mic = 'MIC',
	MixOutput = 'MIX_OUTPUT',
	MobileInput = 'MOBILE_INPUT',
	MobileOutput = 'MOBILE_OUTPUT',
	PcInput = 'PC_INPUT',
	PcOutput = 'PC_OUTPUT',
	SignalGeneratorInput = 'SIGNAL_GENERATOR_INPUT',
	Sum = 'SUM',
	UsbInput = 'USB_INPUT',
	UsbOutput = 'USB_OUTPUT',
	VirtualAudioInput = 'VIRTUAL_AUDIO_INPUT',
	VirtualAudioOutput = 'VIRTUAL_AUDIO_OUTPUT'
}
