import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { PrismBreadcrumbComponent, PrismBreadcrumbItemComponent } from '@shure/prism-angular-components';

import { BreadcrumbsComponent } from './breadcrumbs.component';

@NgModule({
	declarations: [BreadcrumbsComponent],
	exports: [BreadcrumbsComponent],
	imports: [CommonModule, FlexLayoutModule, PrismBreadcrumbComponent, PrismBreadcrumbItemComponent]
})
export class BreadcrumbsModule {}
