export enum DeviceModel {
	AD600 = 'AD600',
	ADTD = 'ADTD',
	ADTQ = 'ADTQ',
	AD4D = 'AD4D',
	AD4Q = 'AD4Q',
	ANIUSB = 'ANIUSB-MATRIX',
	IMXROOM = 'IMX-Room',
	IMXCOMPUTE = 'IMX-Compute',
	MCR = 'ANX4',
	MXA310 = 'MXA310',
	MXA320 = 'MXA320',
	MXA7102FT = 'MXA710-2FT',
	MXA7104FT = 'MXA710-4FT',
	MXA901R = 'MXA901-R',
	MXA901S = 'MXA901-S',
	MXA902S = 'MXA902-S',
	MXA910 = 'MXA910',
	MXA920S = 'MXA920-S',
	MXA920R = 'MXA920-R',
	MXN5C = 'MXN5-C',
	MXN6 = 'MXN-6',
	MXNAMP = 'MXN-AMP',
	MXAMUTE = 'MXA-MUTE',
	MXWAPXD2 = 'MXWAPXD2',
	MXWAPX = 'MXWAPX',
	MXWNDX2 = 'MXWNDX2',
	MXWNDX4 = 'MXWNDX4',
	MXWNDX4G = 'MXWNDX4G',
	MXWNDX8 = 'MXWNDX8',
	MXWNDX8G = 'MXWNDX8G',
	MXW1X = 'MXW1X',
	MXW2X = 'MXW2X',
	MXW6X = 'MXW6X',
	MXW8X = 'MXW8X',
	MV7I = 'MV7i',
	P300 = 'P300',
	P600 = 'P600',
	DCA901 = 'DCA901',
	UCWALL = 'UC-WALL',
	ULXD1RX = 'ULXD4',
	ULXD4Q = 'ULXD4Q',
	ULXD6TX = 'ULXD6',
	ULXD8TX = 'ULXD8',
	JINJICH2 = 'SBC250',
	JINJICH4 = 'SBC450',
	JINJICH8 = 'SBC850',
	QLXD = 'QLXD4',
	UNKNOWN = 'UNKNOWN'
}
