<form [formGroup]="formGroup" *transloco="let t">
	<h1 id="sh-feature-request-dialog-title-h1" mat-dialog-title>
		{{ t('cloud.shared.feature-request.feature-request') }}
	</h1>
	<div id="sh-feature-request-dialog-description-div">{{ t('cloud.shared.feature-request.feature-popup-info') }}</div>
	<mat-dialog-content id="sh-feature-request-dialog-content">
		<ng-container>
			<prism-input
				id="sh-feature-request-dialog-idea-summary-input"
				formControlName="title"
				type="text"
				autofocus="true"
				inputmode="text"
			>
				<span slot="label">{{ t('cloud.shared.feature-request.idea-summary') }}</span>
			</prism-input>

			<mat-form-field id="sh-feature-request-dialog-description-form-field">
				<mat-label id="sh-feature-request-dialog-description-mat-label">{{
					t('cloud.shared.feature-request.more-details')
				}}</mat-label>
				<textarea
					rows="6"
					[placeholder]="t('cloud.shared.feature-request.more-details')"
					matInput
					[formControlName]="'description'"
				></textarea>
				<mat-error
					*ngIf="
						formGroup.controls['description'].touched &&
						formGroup.controls['description'].hasError('required')
					"
					>{{ t('cloud.shared.error-labels.required-field') }}
				</mat-error>
			</mat-form-field>
		</ng-container>
		<div id="sh-feature-request-dialog-contact-info-div">
			{{ t('cloud.shared.feature-request.feature-request-contact-info') }}
		</div>
		<div class="sh-feature-request-dialog-buttons" id="sh-feature-request-dialog-action-buttons-div">
			<span slot="end">
				<prism-button variant="text" class="prism-button" size="md" (click)="closeDialog()">
					{{ t('cloud.shared.button-text.cancel') }}
				</prism-button>

				<prism-button
					variant="text"
					swap="false"
					checked="false"
					size="md"
					[disabled]="formGroup.invalid || !isFormChanged"
					(click)="doAction()"
				>
					{{ t('cloud.shared.button-text.send') }}
				</prism-button>
			</span>
		</div>
	</mat-dialog-content>
</form>
