<ng-container *transloco="let t; read: 'common.device-tooltips.identify'">
	<prism-icon-button
		icon="sh-id-nobox"
		[id]="id()"
		[class.sh-device-identifying]="isIdentifying()"
		[label]="isIdentifying() ? t('stop-identifying') : t('identify')"
		[disabled]="disabled()"
		(click)="identifyDevice($event)"
	></prism-icon-button>
</ng-container>
