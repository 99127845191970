import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { AgGridModule, ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { InventoryDevice } from '@shure/cloud/shared/models/devices';

export interface StatusCellData {
	id: string;
	isOffline: boolean;
	isUpdating: boolean;
	noPackagesAvailable: boolean;
}

export type StatusRendererComponentParamsType = ICellRendererParams<InventoryDevice, StatusCellData>;

export function makeComparableStatusCellData(obj: StatusCellData): Omit<StatusCellData, 'id'> {
	const { id, ...rest } = obj;
	return rest;
}

interface StatusDisplay {
	icon: string;
	class: string;
	message: string;
}

/**
 * ```sh-status-renderer``` is a view component for displaying status (offline, updating) for a device
 */
@Component({
	selector: 'sh-status-renderer',
	templateUrl: './status-renderer.component.html',
	styleUrls: ['./status-renderer.component.scss'],
	standalone: true,
	imports: [AgGridModule, CommonModule, MatIconModule, MatTooltipModule, TranslocoModule]
})
export class StatusRendererComponent implements ICellRendererAngularComp {
	public params!: StatusRendererComponentParamsType;
	public cellData?: StatusCellData;
	public display?: StatusDisplay;

	constructor(public readonly transloco: TranslocoService) {}

	public agInit(params: StatusRendererComponentParamsType): void {
		this.update(params);
	}

	public refresh(params: StatusRendererComponentParamsType): boolean {
		this.update(params);
		return true;
	}

	private update(params: StatusRendererComponentParamsType): void {
		this.params = params;
		this.cellData = params.value ?? undefined;

		if (this.cellData?.isOffline) {
			this.display = {
				icon: 'sh-error-outline',
				class: 'error-icon',
				message: this.transloco.translate('cloud.device-management.firmware.updates-table.device-offline')
			};
		} else if (this.cellData?.isUpdating) {
			this.display = {
				icon: 'sh-error-outline',
				class: 'error-icon',
				message: this.transloco.translate(
					'cloud.device-management.firmware.updates-table.device-update-in-progress'
				)
			};
		} else if (this.cellData?.noPackagesAvailable) {
			this.display = {
				icon: 'sh-error-outline',
				class: 'error-icon',
				message: this.transloco.translate(
					'cloud.device-management.firmware.updates-table.no-packages-available'
				)
			};
		} else {
			this.display = undefined;
		}
	}
}
