<prism-breadcrumb id="sh-breadcrumbs-main-content">
	@for (crumb of breadcrumbs; track trackByFn) {
		<prism-breadcrumb-item
			id="sh-breadcrumb-{{ crumb.name }}-breadcrumb-item"
			(click)="onCrumbClick(crumb)"
			[attr.disabled]="disableLast && breadcrumbs.indexOf(crumb) === breadcrumbs.length - 1 ? true : null"
			[class.hidefirstcrumb]="breadcrumbs.length > 2 && breadcrumbs.indexOf(crumb) === 0"
		>
			{{ crumb.name }}
		</prism-breadcrumb-item>
	}
</prism-breadcrumb>
